import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import '../controllers';

import '@fortawesome/fontawesome-free';
import '@fortawesome/fontawesome-free/css/all';

Turbolinks.start();
ActiveStorage.start();

document.addEventListener('DOMContentLoaded', function() {
  window.bootstrap = require('bootstrap');

  const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  const popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
    return new window.bootstrap.Popover(popoverTriggerEl);
  });

  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  const tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
    return new window.bootstrap.Tooltip(tooltipTriggerEl);
  });

  const toastElList = [].slice.call(document.querySelectorAll('.toast'));
  const toastList = toastElList.map(function(toastEl) {
    return new window.bootstrap.Toast(toastEl).show(); // No need for options; use the default options
  });
});
