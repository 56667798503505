import { Controller } from '@hotwired/stimulus';
import { application } from './';

application.register('user-role', class extends Controller {
  static targets = [
    'roleType', 'storeDiv', 'regionalDiv', 'buyerDiv', 'supplierDiv', 'productFamilyDiv'
  ];

  userRoles = {
    supplierDiv: 'supplier',
    storeDiv: 'store',
    regionalDiv: 'regional',
    productFamilyDiv: 'product_family',
    buyerDiv: ['restricted_buyer', 'buyer', 'manager']
  };

  connect() {
    this.setDependentField();
    $(this.roleTypeTarget).select2().on('change', () => this.setDependentField());
  }

  setDependentField() {
    Object.keys(this.userRoles).forEach((elemName) => {
      const roleName = this.userRoles[elemName];
      const elem = this[`${elemName}Target`].classList;

      this._selectedRole(roleName) ? elem.remove('hidden') : elem.add('hidden');
    });
  }

  _selectedRole(roleName) {
    const selectedRole = this.roleTypeTarget.value;

    return Array.isArray(roleName) ? roleName.includes(selectedRole) : roleName === selectedRole;
  }
});
