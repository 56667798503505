/* eslint-disable no-tabs */
module.exports = {
  tooltips: {
    charts: {
      activities:
        ' Según el criterio seleccionado en el cuadro FILTRO:<br>' +
        '  – Mientras más específicos sean los criterios, más eficientes serán las informaciones –<br>' +
        ' 											<br>' +
        ' (X)  SIN VENTAS<br>' +
        ' En esta selección, usted puede ver la proporción de abastecimiento de su mix que no posee' +
        ' registro de ventas, es posible generar una lista de productos, con doble pulsar sobre la barra:<br>' +
        '■ CELESTE = Productos Activos, inclusive los impedidos de comprar, con o sin stock.<br>' +
        '■ ROSADO = Productos activos para compra.<br>' +
        '■ NARANJA = Activos para compra sin stock.<br>' +
        '■ MARRÓN = Activos para compra sin stock y sin pedidos pendientes (1).<br>' +
        '	(1) productos potenciales para excluir del Surtido merceológico.<br>' +
        '■ AZUL = Inactivos con stock.<br>' +
        ' 											<br>' +
        '(X) TODO<br>' +
        'En esta selección, usted ve la proporción de abastecimiento de todo el Surtido, se' +
        ' puede generar una lista de productos, pulsando dos veces sobre la barra:<br>' +
        '■ CELESTE = Todos los productos activos, inclusive los impedidos de comprar,' +
        ' con o sin ventas, con o sin stock, con o sin pedidos pendientes.<br>' +
        '■ ROSADO = Productos activos para compra.<br>' +
        '■ NARANJA = Activos para compra, con o sin ventas, sin stock, con o sin pedidos pendientes <br>' +
        '■ MARRÓN = Activos para compra, con o sin ventas, sin stock, sin pedidos pendientes (2) .<br>' +
        '	(2) productos del Surtido de ventas sin abastecimiento => punto de atención para compras.<br>' +
        '■ AZUL = Inactivos, con o sin ventas, con stock (3).<br>' +
        '	(3) productos para liquidación.<br>' +
        ' 											<br>' +
        'Recomendaciones de Uso<br>' +
        '1.Profundice su investigación, usando la estructura merceológica en el filtro del panel.<br>' +
        '2.Ordene las listas – creciente o decreciente – según el foco de su análisis.<br>' +
        '3.Identifique en las listas:<br>' +
        ' ● Productos con pedidos pendientes: acción de acompañamiento y/o sustitución del proveedor.<br>' +
        '	● Productos sin proveedor asociado: actualice el registro del producto en el ERP.<br>' +
        '	● Productos para excluir (1) del Surtido – Desactivar en su ERP (bloqueo de entrada).<br>' +
        '<br>' +
        '' +
        ' - Si es similar y no tiene un% definido:<br>' +
        'Si alguno de los productos que componen el similar tiene stock es correcto y no aparece<br>' +
        'En la parte de compra cuentan como UNO<br>' +
        ' - Si es similar y ha definido %:<br>' +
        'Los productos que componen el similar computan individualmente tanto para compra como' +
        ' para fuera de stock y sin pedido.',
      virtualstockalert:
        'Alerta de stock virtual – De acuerdo con el criterio seleccionado en el cuadro FILTRO: <br>' +
        ' – mientras más específicos sean los criterios, más eficientes serán las informaciones –<br>' +
        ' 											<br>' +
        'En este gráfico, usted ve la proporción de su Surtido sin ventas en intervalos de tiempo, Siendo:<br>' +
        '■ D7   (de 1 a 7 días) <br>' +
        '■ D30  (de 8 a 30 días)<br>' +
        '■ D60  (de 31 a 60 días)<br>' +
        '■ D90  (de 61 a 90 días)<br>' +
        '■ D+   (más de 90 días)<br>' +
        ' 											<br>' +
        '(X) CON STOCK:<br>' +
        'En esta selección, usted ve posibles Stocks Virtuales o fallas en el abastecimiento' +
        ' de gondolas/anaqueles <br>' +
        ' 											<br>' +
        '(X) TODO: <br>' +
        'En esta selección, usted ve productos potenciales para liquidación y/o bloqueo de entrada (desactivar)<br>' +
        ' 											<br>' +
        'Pulse dos veces sobre la faja de información de la que quiere detalles. O en el ícono de base' +
        ' de datos al lado de la “I” de informaciones, usted obtendrá la lista completa de todas las clases.<br>' +
        ' 											<br>' +
        'Recomendaciones de Uso<br>' +
        'Mientras mayor sea el valor “días sin ventas”, mayor la probabilidad de stock virtual. Por lo tanto,' +
        ' priorice sus análisis por los intervalos “d+”, “d90”, “d60” y regresivamente (*).<br>' +
        '(*) cuando la estructura merceológica sufre alta deterioración, invierta la investigación para intervalos' +
        ' de tiempo creciente – ejemplo: “d30”, “d60”, “d90” y así sucesivamente.<br>' +
        ' 											<br>' +
        'El conteo de días sin ventas está asociada al pasaje del código de producto en el PDV, - por lo tanto,' +
        ' considere informaciones en la columna “Últ.Entrega”<br>',
      ruptures:
        'De acuerdo con el criterio seleccionado en el cuadro FILTRO:<br>' +
        ' – mientras más específicos sean los criterios, más eficientes serán las informaciones –<br>' +
        ' 											<br>' +
        'En el centro del gráfico, usted ve el índice de faltantes de facturación no realizado el día anterior' +
        ' (stock cero) para productos que el Kikker tiene predicción de ventas. Con el ‘promt’ (indicador del mousse)' +
        ' sobre el índice de faltante, usted ve la facturación realizada, la facturación no realizada y el margen' +
        ' no realizado – también referentes al día anterior.<br>' +
        ' 											<br>' +
        'Pulse dos veces sobre la faja de información de la que quiera detalles. O en el ícono de base de datos al' +
        ' lado de la “I” de informaciones, usted obtendrá la lista completa de todas las clases.<br>' +
        ' 											<br>' +
        'Recomendaciones de Uso<br>' +
        '1.Investigar stocks excedentes en otras tiendas, decidir sobre transferencias y accionar a la gestión' +
        ' de compras para una solución definitiva.<br>' +
        '2.Establecer metas para este índice conforme la curva de clasificación ABCDE, estructura merceológica y' +
        ' disponibilidad financiera para el giro de stock.<br>' +
        ' 											<br>' +
        'Otro beneficio en estas listas se refiere a las negociaciones futuras con los proveedores en atraso de' +
        ' entrega, considerando los Faltantes de productos con pedidos pendientes. La lista indicará facturación' +
        ' y margen no realizados.<br>' +
        ' 											<br>' +
        'Identifique los productos derivados y comuníqueselo a los respectivos encargados de sector para las' +
        ' debidas correcciones de registro para que esos productos no interfieran en las mediciones de Faltantes. <br>',
      virtualAlert:
        'Faltante operativo – Con la proyección de demanda el Kikker entiende el comportamiento de venta de cada' +
        ' producto, y si la venta sale del comportamiento, inclusive con stock suficiente, señalamos el probable' +
        ' Faltante, posiblemente causado por stock virtual o desabastecimiento de expositores/anaqueles. Siendo' +
        ' necesaria la acción del operativo y comercial para ajuste de stock y abastecimiento.',
      negative:
        'Stock negativo – De acuerdo con el criterio seleccionado en el cuadro FILTRO<br>' +
        ' – Mientras más específicos sean los criterios, más eficientes serán las informaciones –<br>' +
        ' 											<br>' +
        'En este gráfico, usted ve las ventas ‘pendientes’, o sea, salidas de stock cero.<br>' +
        ' 											<br>' +
        'Se debe realizar, sistemáticamente, el comparativo del stock físico con el stock generado por el' +
        ' sistema de gestión. El objetivo es indicar errores de registro, entradas de facturas, emisiones de' +
        ' facturas y otras inconsistencias que ocasionan divergencias entre los stocks, para que se realicen todos' +
        ' los ajustes necesarios.<br>' +
        ' 											<br>' +
        'Pulse dos veces sobre la faja de información de la que quiere detalles. O en el ícono de base de datos' +
        ' al lado de la “I” de informaciones, usted obtendrá la lista completa de todas las clases.<br>' +
        ' 											<br>' +
        'Atención para las posibles causas<br>' +
        ' 											<br>' +
        'Suele haber códigos de entrada que no tienen salida – la salida se da por otro código, lo que provoca' +
        ' aumentos de stock injustificados y puede configurar omisión de salida (porque no existe stock físico' +
        ' compatible).  De igual manera, el ítem que sale del stock nunca entró, caracteriza omisión de entrada' +
        ' (stock negativo)<br>' +
        ' 											<br>' +
        'Otra situación común sucede cuando la empresa compra el mismo producto de diferentes proveedores.' +
        ' Por ejemplo, el Proveedor A vende caja con 24 unidades; el Proveedor B vende caja con 12 unidades; ' +
        'por su vez, el Proveedor C vende unidades.<br>',

      last30:
        'Stock excedente – De acuerdo con el criterio seleccionado en el cuadro FILTRO:<br>' +
        ' – Mientras más específicos sean los criterios, más eficientes serán las informaciones –<br>' +
        ' 											<br>' +
        'En este gráfico, usted ve los excedentes de stock en relación con las predicciones de ventas, ' +
        'según periodo de tiempo en ventas futuras que usted seleccione:.<br>' +
        ' 											<br>' +
        'Las proyecciones disponibles para las ventas futuras son de:<br>' +
        '■ 15 dias<br>' +
        '■ 30 dias<br>' +
        '■ 45 dias<br>' +
        '■ 60 dias<br>' +
        '■ 90 dias<br>' +
        ' 											<br>' +
        'Pulse dos veces sobre la faja de información de la que quiera detalles. O en el ícono de base de' +
        ' datos al lado de la “I” de informaciones, usted obtendrá la lista completa de todas las clases.<br>' +
        ' 											<br>' +
        'Recomendaciones de Uso<br>' +
        ' 											<br>' +
        'Identifique los ítems que el volumen (valor/cantidad) en stock da oportunidad para una acción de' +
        ' liquidación. El objetivo es generar liquidez financiera para el abastecimiento adecuado y reducción' +
        ' continua de Faltantes de stock de productos que tienen demanda conocida y prevista.<br>' +
        ' 											<br>' +
        'Stock en la clasificación X significa que no existe siquiera el rastro de venta de estos productos y,' +
        ' por lo tanto, de prioridad a esta clase en sus iniciativas de investigación y análisis. Puede ser que' +
        ' gran parte de este excedente sea apenas virtual (acción para inventario), o puede tratarse también de' +
        ' aquellos productos que entran con un código y salen con otro código (relacionados a las ventas pendientes).' +
        ' Necesario corregir registros y “dar las bajas” (salidas). <br>' +
        ' 											<br>' +
        'Este gráfico es muy útil para acompañar la migración del recurso financiero aplicado en stock – de las' +
        ' clases de menor giro a las clases de mayor giro de la curva de Paretto – a medida que haya adherencia' +
        ' de sus compradores a las sugerencias de compras del Kikker. <br>',
      virtualStockAlert: `
        Indicador que muestra los productos de cada tienda que tienen más probabilidades de tener falta de ventas por stock virtual o fallo de exhibición, o incluso fallo de precio.
        <br>
        <br>
        Los grupos de probabilidad se dividen en:<br>
        -Muy alta<br>
        -Alto<br>
        -Promedio<br>
        -Bajo<br>
        <br>
        La tasa en % en el centro del gráfico muestra la proporción de artículos con mayor probabilidad de perder ventas en función del total de artículos activos, centrándose siempre en los grupos Muy Alto y Alto según el comportamiento de D-1.
        <br>
        <br>
        La información considera el ciclo/comportamiento de ventas de cada producto. cuanto mayor es la probabilidad, más probable es que el producto en la tienda tenga un problema y se deben tomar medidas diariamente para que el producto esté disponible en los estantes.
      `,
      rupturesalert:
        'Alerta de FALTANTES – De acuerdo con el criterio seleccionado en el cuadro FILTRO:<br>' +
        ' – Mientras más específicos sean los criterios, más eficientes serán las informaciones –<br>' +
        ' 											<br>' +
        'En este gráfico, usted ve los productos que, por las proyecciones de ventas, tendrán sus stocks a cero,' +
        ' en días, en las siguientes fajas de tiempo:<br>' +
        '■ D7	(de 1 a 7 dias) <br>' +
        '■ D15	(de 8 a 15 dias)<br>' +
        '■ D30	(de 16 a 30 dias)<br>' +
        ' 											<br>' +
        'Pulse dos veces sobre la faja de información de la que quiera detalles.<br>' +
        ' 											<br>' +
        'Recomendación de Uso<br>' +
        ' 											<br>' +
        'En primer lugar, investigue stocks excedentes en otras tiendas – a medida que surjan adherencias a las' +
        ' sugerencias de compras del Kikker, eliminará excesos de stock para transferencia entre tiendas.<br>' +
        ' 											<br>' +
        'Responsable de compras debe asumir acción de abastecimiento. Utilice el orden – creciente o decreciente-' +
        ' por pedidos pendientes y por clase – para identificar posibles anticipaciones de pedidos de compras.<br>' +
        ' 											<br>' +
        'Atención: garantice que las configuraciones de pedidos en el Kikker estén actualizadas<br>' +
        ' 											<br>' +
        'En los parámetros de sugerencia de pedidos de compras, el gestor puede definir si el stock actual debe' +
        ' o no considerarse para las coberturas en las predicciones de ventas – como ejemplo, sugerimos no considerar' +
        ' el stock actual para departamentos/sectores de alto grado de pérdida y/o deterioración.<br>' +
        ' 											<br>' +
        'Otro parametro, definido por departamento/sector/categoria/sub-categoria, determina si el stock actual debe' +
        ' o no ser considerado para las coberturas en las predicciones de ventas – como ejemplo, sugerimos no' +
        ' considerar stock actual para departamentos/setores de alto grado de perdida y/o desperdicio. <br>',
      abcshare:
        'Participación del Surtido- De acuerdo con el criterio seleccionado en el cuadro FILTRO, se puede analizar' +
        ' cualquier nivel de la estructura mercadológica.<br>' +
        '                        <br>' +
        'En este gráfico, usted ve:<br>' +
        '■ 10% de los productos con mayor facturación<br>' +
        '■ 20% de los productos con mayor facturación<br>' +
        '■ 50% de los productos con mayor facturación<br>' +
        '■ de los productos con mayor facturación<br>' +
        '                        <br>' +
        '(X) VENTAS:<br>' +
        'En esta selección, usted ve la participación de los productos respecto a la facturación – o sea, cuanto' +
        ' de su Surtido tuvo giro en los últimos 30 (treinta) días media móvil.<br>' +
        'Al colocar el mousse sobre el punto de la curva refleja el valor facturado hasta la fecha D-1 siendo en' +
        ' la media móvil 30 días.<br>' +
        ' 											<br>' +
        '(X) STOCK:<br>' +
        'En esta selección, usted ve la participación de los productos respecto al stock en valor para los' +
        ' últimos 30 (treinta) días, media móvil.<br>' +
        'Al colocar el mousse sobre el punto de la curva refleja el valor facturado hasta la fecha D-1 siendo' +
        ' en la media móvil 30 días.<br>' +
        ' 											<br>' +
        'Recomendación de Uso<br>' +
        ' 											<br>' +
        'Pulse dos veces sobre el punto de información (intersección de las fajas en el eje X con la línea' +
        ' del gráfico) para detalles.<br>' +
        ' 											<br>' +
        'Que es media móvil 30 días.<br>' +
        'Se suma el valor de los últimos 30 días y se divide por 30, lo que resulta en la media aritmética' +
        ' de los últimos 30 días.<br>' +
        'Al día siguiente, el Kikker excluye el valor del día más antiguo y adiciona un día nuevo más, de' +
        ' modo que siempre tendremos la media aritmética de los últimos 30 días.<br>' +
        'El valor de la media móvil 30 días comparado con la facturación o stock del mes pasado, podemos' +
        ' entender si estamos en un sentido creciente o decreciente con relación al último mes.<br>' +
        ' 											<br>' +
        'Otro análisis importante que podemos realizar en este gráfico es el potencial que se está explotando' +
        ' del Surtido seleccionado en el filtro.<br>' +
        'Mientras más abarcadora la curva, iniciando del punto más bajo a la izquierda del gráfico y llegando' +
        ' al nivel más alto posible al extremo superior derecho del gráfico tenemos el Surtido siendo bien' +
        ' dimensionado.<br>' +
        'Cuando el gráfico presenta “codos” en la curva, eso indica que el Surtido está malo, y debe ser revisado.<br>',

      classshares:
        'De acuerdo con el criterio seleccionado en el cuadro FILTRO:<br>' +
        ' – Mientras más específicos sean los criterios, más eficientes serán las informaciones –<br>' +
        ' 											<br>' +
        'En este gráfico, Ud visualiza los productos por clase que, en el dia de ayer, estaban con:<br>' +
        '■ Curva ABC;<br>' +
        '■ Margen negativo;<br>' +
        '■ Productos vendidos;<br>' +
        '■ Productos con faltantes;<br>' +
        '■ Variaciones de costo de mercaderia;<br>' +
        '■ Variaciones de precios de ventas.<br>' +
        ' 											<br>' +
        'Pulse dos veces sobre la faja de información de la que quiera detalles.<br>' +
        ' 											<br>' +
        'Recomendaciones de Uso<br>' +
        ' 											<br>' +
        'Investigue errores de negociacion en compras en las variaciones de costo y errores de' +
        ' precificacion en las variaciones de venta. <br>' +
        ' 											<br>' +
        'Atencion con la lista de productos con margen negativo (bonificaciones negociadas).<br>',
      network_performance:
        'Performance – De acuerdo con el criterio seleccionado en el cuadro FILTRO, la curva exhibe' +
        ' datos en cualquier nivel de la estructura mercadológica.<br>' +
        ' 											<br>' +
        'En este gráfico, usted ve la curva, por semana o por mes, los valores registrados de:<br>' +
        '● Ventas<br>' +
        'El valor exhibido es el valor facturado acumulado en el periodo de análisis, mensual o semanal.<br>' +
        ' 											<br>' +
        '● Faltantes<br>' +
        'El valor exhibido se refiere a la media simple apurada en el periodo en análisis, mensual o semanal.<br>' +
        ' 											<br>' +
        '● Mermas<br>' +
        'El valor exhibido es el total de desperdicios conocidos y desconocidos apurado en el periodo' +
        ' en análisis, mensual o semanal.<br>' +
        'Importante: el valor exhibido en el Kikker es el valor lanzado por el cliente no habiendo ningún' +
        ' tratamiento de datos en esta información.<br>' +
        ' 											<br>' +
        '● Stock<br>' +
        'Si el análisis es mensual, el valor exhibido es la media del stock en el mes en análisis,' +
        ' sumatoria del mes en análisis dividido por 30.<br>' +
        'Si el análisis es semanal, el valor exhibido es la media del stock en la semana en análisis,' +
        ' sumatoria del mes en análisis dividido por 7.<br>' +
        ' 											<br>' +
        '● Tasa de Faltantes<br>' +
        'El valor exhibido es el valor de perdidas de ventas por faltantes registrado en el periodo dividido' +
        ' por la facturación del periodo, o sea, cuál es el porcentaje de faltantes registrado en el periodo.<br>' +
        ' 											<br>' +
        '● Giro de Stock.<br>' +
        'El valor exhibido es la cantidad de veces que el stock gira respecto a la facturación del periodo' +
        ' analizado, ej: Facturación de 100 con stock medio de 50, eso equivale a 2. Giro de stock igual a' +
        ' dos veces.<br>' +
        ' 											<br>' +
        'Posicione el prompt del mousse sobre la línea en las referencias del eje X para detalles (explicación)<br>',
      curve_abc:
        'Curva ABC – Demuestra la venta del día anterior distribuida por la clasificación ABC, en que cada' +
        ' curva representa su porcentaje de facturación de los últimos 90 días.<br>' +
        '● Curva A = 50%<br>' +
        '● Curva B = 20%<br>' +
        '● Curva C = 15%<br>' +
        '● Curva D = 10%<br>' +
        '● Curva E = 5%<br>',
      top_sales:
        'Top de ventas – Son los 20% de los SKU más vendidos del Surtido de productos. Referentes a la' +
        ' media de los últimos 90 días.',
      cost_var:
        'Variación de costo – Demuestra los ítems que tuvieron la última compra con variación de 8% para' +
        ' menos o para más respecto a la última compra, el objetivo es evidenciar variación brusca en la compra.  ',
      sale_var:
        'Variación de venta – Demuestra los ítems que tuvieron la última venta con variación de 8% para' +
        ' menos o para más respecto a la última venta, el objetivo es encontrar errores de definición de precios.  ',
      top_ruptures: 'Top de Faltantes - Son 20% de los SKU´s que más faltan. Referente a los últimos 30 días.',
      top_ruptures_in_value:
        'Top de Faltantes por valor – Los 20% de los SKU’s que más tuvieron pérdida por faltante.' +
        ' Referente a los últimos 30 días. ',
      top_ruptures_recidivism:
        'Top de Faltantes por reincidencia – Los 20% de los SKU’s que estuvieron en Faltante por más días.' +
        ' Referente a los últimos 30 días.',
      top_ruptures_lost_products:
        'Top de Faltantes en cantidad -  Son 20% de los SKU´s que más se rompen frecuentemente en cantidad.' +
        ' Referente a los últimos 30 días.',
      top_breaks_in_value:
        'Top de desperdicios en valor – Los 20% de los SKU’s que más tuvieron pérdida por desperdicio.' +
        ' Referente a los últimos 30 días.',
      top_breaks_recidivism:
        'Top de desperdicios en reincidencia - Los 20% de los SKU’s que estuvieron en desperdicio por más' +
        ' días. Referente a los últimos 30 días.',
      top_breaks_lost_products:
        'Top de desperdicios en cantidad – Son 20% de los SKU’s que más se rompen a menudo en cantidad.' +
        ' Referente a los últimos 30 días.',
      ruptureslogistics:
        'Muestra productos que se han roto en las tiendas, pero que están disponibles en el Centro de distribución.',
    },
    monitoring: {
      title:
        'De acuerdo con el filtro guardado, Kikker le enviará por e-mail (cuenta del usuario) un resumen' +
        ' informativo de los indicadores de desempeño, marcando los movimientos ascendentes (en verde) o descendentes' +
        ' (en rojo), de acuerdo con la polaridad de la métrica. <br> → D = Diario – reporte enviado diariamente,' +
        ' compara el último dia de datos en Kikker con el del dia anterior (ej: Reporte enviado hoy compara los' +
        ' dados de ayer con los de anteayer) <br> → S = Semanal – reporte enviado los lunes, compara la suma de' +
        ' la semana actual con la semana anterior (ej: Reporte enviado dia 16/10 compara la suma de los datos de' +
        ' 09/10 a 15/10 en relacion a la suma de los datos de 02/10 a 08/10) <br> → M = Mensual - el reporte será' +
        ' enviado todo los dias 1 del mes e irá a comparar la suma del mes actual con el mes anterior (ej: Reporte' +
        ' enviado dia 01/10 compara la suma de los datos de 01/09 a 30/09 en relaion a la suma de los datos de 01/08' +
        ' a 31/08) <br> Es posible la grabacion (memorizacin) de hasta 7 (siete) criterios de filtro. Para guardar un' +
        ' criterio, basta insertar los parametros en el cuadro Filtros y hacer click sobre el "OJO". <br>',
    },
    cockpit_selectors: {
      business_group: 'Grupo Economico - es necesario completar',
      regional: 'Division dentro del grupo economico - es necesario completar',
      store_cluster: 'Agrupación de Tiendas',
      supplier: 'Código o Nombre del Proveedor (deje en blanco para todos)',
      store: 'Código o Nombre de la unidad (deje en blanco para todas)',
      market_structure:
        'Departamento > Sector > Categoria > Subcategoria (en blanco para todos los niveles)' +
        ' - haga click sobre la seleccion para expandir los níveles -',
      product_families: 'Familia de productos (en blanco para todos los niveles) -',
      product_supply_types:
        'Recepcion de la mercaderia por el proveedor o por el centro de' + ' distribucion (en blanco para ambos)',
      product_class:
        'Clase de la curva ABCDE - Paretto (en blanco para todas las clases) – X se' +
        ' refiere a produtos sin ventas hace mas de 90 (dias) o productos nuevos en lanzamiento (nuevos)',
      product: 'Código interno o Descripcion de un ítem (SKU-StoreKeepingUnit) - en blanco para todos los productos -',
      erp_buyer: 'Código o Nombre del Comprador (deje en blanco para todos)',
      launch:
        'SOLO LANZAMIENTOS: son los productos registrados en los últimos 90 (noventa) dias' +
        ' corridos. SIN LANZAMIENTOS: son solamente los productos registrados hace mas de 90 (noventa)' +
        ' dias corridos. En blanco para TODOS LOS PRODUCTOS. - obs. fecha de registro informada en el' +
        ' cuadro DETALLES > PRODUCTO > ENTRO EN LINEA -',
      promotion:
        'SOLO PROMOCION: Solamente los productos en promocion. SIN PROMOCION: Productos que no' +
        ' estan en promocion. TODOS LOS PRODUCTOS: Todos los productos en promocion o no.',
    },
    buyers_view: {
      performance: 'Preencher',
      participation: 'Preencher',
      mix: 'Preencher',
      ruptures: 'Preencher',
      quality_excess: 'Preencher',
      ruptures_alert: 'Preencher',
    },
    product_rounding:
      'Cambiar los datos para todos, en la parte superior, le permite hacer un único cambio' +
      ' que se aplica a todas las tiendas o los datos se pueden cambiar de una tienda a otra,' +
      ' simplemente yendo a la línea de la tienda. <br/>' +
      'Tipos de redondeo <br>' +
      '• Unidad de compra → Redondea el producto a la unidad de compra. Es necesario definir el' +
      ' Porcentaje de redondeo en el campo “Unidad de redondeo. Compra ”. Al ingresar, por ejemplo,' +
      ' 30%, el valor del pedido calculado, de la parte no completa, que sea mayor que 0.3 se' +
      ' redondeará hacia arriba, si no hacia abajo. El pedido de ejemplo en la unidad de compra' +
      ' fue 10,2, se redondeará a 10. El pedido fue 10,3, se redondeará a 11.    <br>' +
      '• Palet → Redondea la compra de palets. Es necesario definir el “Redondeo de la Unidad.' +
      ' Compra ”, ya que inicialmente habrá un ajuste en la unidad de compra. También es necesario' +
      ' definir “Redondeo de la unidad de pedido” que redondeará el palet. Ejemplo: <br>' +
      '◦ Redondeo de la unidad. Compra: 30%  <br>' +
      '◦ Redondeo de la unidad de pedido: 25% <br>' +
      '◦ Capacidad del palet: 100 cartones <br>' +
      '◦ El pedido fue de 24,3 cajas, se redondeará a 25 cajas. Como 25 cajas sirven al 25% del' +
      ' palet tendremos un pedido de 100 cajas, es decir, un palet <br>' +
      '• Capa → Redondea la compra para capas de paleta. Es necesario definir el “Redondeo de la' +
      ' Unidad. de Compra ”, ya que inicialmente habrá un ajuste en la unidad de compra. <br>' +
      'También es necesario definir “Redondeo de la unidad de pedido” que redondeará la capa de palet. Ejemplo: <br>' +
      '◦ Redondeo de la unidad. Compra: 30% <br>' +
      '◦ Redondeo de la unidad de pedido: 30% <br>' +
      '◦ Capacidad de la capa: 10 cajas <br>' +
      '• El pedido fue de 24,3 cajas, se redondeará a 25 cajas. Como 25 cajas sirven 2,5 capas' +
      ' tendremos un pedido de 30 cajas, es decir, 3 capas <br>' +
      '• Factor de multiplicación de la unidad de compra → Realice el pedido en múltiplos de la unidad de compra. <br>' +
      '◦ Redondeo de la unidad. Compra: 30% <br>' +
      '◦ Redondeo de la unidad de pedido: 30% <br>' +
      '◦ Factor de multiplicación de unidades de compra: 20 <br>' +
      '◦ El pedido en la unidad de compra 25.2, se redondeará a 25 cajas. 5 cajas son el 25% del factor' +
      ' de multiplicación (5/20 = 0,25) y el redondeo de la unidad del pedido es del 30%,' +
      ' el pedido será de 20 unidades de compra. <br>' +
      '• Factor de multiplicación de unidades de venta → Realizar el pedido en múltiplos de unidades de venta. <br>' +
      '◦ Redondeo de la unidad. Compra: 30% <br>' +
      '◦ Redondeo de la unidad de pedido: 30% <br>' +
      '◦ Factor de multiplicación de unidades de venta: 20 <br>' +
      '◦ Unidad de venta por unidad de compra: 10 unidades <br>' +
      '◦ El pedido en la unidad de compra 25.2, se redondeará a 25 cajas, es decir, 250 unidades' +
      ' vendidas. 250/20 = 12.5 el redondeo de la unidad de pedido es 30%, se redondeará a 13' +
      ' factores de multiplicación, es decir, el pedido será de 260 unidades de venta o 26 unidades de compra. <br>',
  },
};
