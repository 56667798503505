import { Controller } from '@hotwired/stimulus';
import { application } from './';
import Ajax from '../packs/components/utils/ajax';
import i18n from '../packs/components/utils/i18n';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import esLocale from '@fullcalendar/core/locales/es';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';

application.register('calendar-product', class extends Controller {
  static targets = ['container', 'loading'];
  static values = { url: String };

  connect() {
    window.resizeTo(680, 590);
    this._mountCalendar();
    this._productChildren();
  }

  _mountCalendar() {
    const calendar = new Calendar(this.containerTarget, {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
      locales: [ptBrLocale, esLocale],
      headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay,list'
      },
      defaultView: 'month',
      height: 450,
      locale: i18n.locale,
      buttonIcons: true,
      weekNumbers: false,
      navLinks: false,
      editable: false,
      eventLimit: false,
      refetchResourcesOnNavigate: true,
      events: (options, callback) => {
        Ajax({
          url: this.urlValue + location.search,
          data: { sds: this._formatDate(options.start), sdf: this._formatDate(options.end) },
          dataType: 'json',
          beforeSend: () => this.loadingTarget.classList.remove('hidden'),
          success: (events) => {
            events = events.map((item) => {
              return { color: '#319990', title: item.supplier_name, start: item.next_visit };
            });
            callback(events);
            this.loadingTarget.classList.add('hidden');
          }
        });
      }
    });

    calendar.render();
  }

  _formatDate(date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    const year = date.getFullYear();
    if(day < 10) day = `0${day}`;
    if(month < 10) month = `0${month}`;
    return `${month}-${day}-${year}`;
  }

  _productChildren() {
    // TODO: verificar se é utilizado em algum lugar
    // if $('#product_children').length
    //   window.resizeTo(780, 500)
    //   $('#product_children').dataTable(
    //     footerCallback: (row, data, start, end, display)->
    //       $('.dataTables_filter input').addClass("form-control input-sm").attr('placeholder', i18n.t('general.search'))
    //     ,
    //     columnDefs: [{width: '135px', targets: [0]}],
    //     order: [[$('table').data('order-id') || 0, $('table').data('order-dir') || 'asc']]
    //   )
  }
});
