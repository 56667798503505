/* eslint-disable no-tabs */
module.exports = {
  tooltips: {
    charts: {
      activities:
        ' De acordo com o critério selecionado no quadro FILTRO:<br>' +
        '  – quanto mais específicos forem os critérios, mais eficientes serão as informações –<br>' +
        ' 											<br>' +
        ' (X)  SEM VENDAS<br>' +
        ' Nesta seleção, você visualiza a proporção de abastecimento de seu mix que não há registro de vendas,' +
        ' sendo possível gerar uma lista de produtos, com duplo clique sobre a barra:<br>' +
        '■ VERDE ÁGUA = Produtos Ativos, inclusive os impedidos de comprar, com ou sem estoque.<br>' +
        '■ ROSA = Produtos ativos para compra.<br>' +
        '■ LARANJA = Ativos para compra sem estoque.<br>' +
        '■ MARRON = Ativos para compra sem estoque e sem pedidos pendentes (¹).<br>' +
        '	(¹) produtos potenciais para excluir do mix mercadológico.<br>' +
        '■ AZUL  = Inativos com estoque.<br>' +
        ' 											<br>' +
        '(X) TUDO<br>' +
        'Nesta seleção, você visualiza a proporção de abastecimento de todo o mix, sendo possível gerar uma lista' +
        ' de produtos, com duplo clique sobre a barra:<br>' +
        '■ VERDE ÁGUA = Todos os produtos ativos, inclusive os impedidos de comprar, com ou sem vendas, com ou sem' +
        ' estoque, com ou sem pedidos pendentes.<br>' +
        '■ ROSA = Produtos ativos para compra.<br>' +
        '■ LARANJA = Ativos para compra, com ou sem vendas, sem estoque, com ou sem pedidos pendentes.<br>' +
        '■ MARRON = Ativos para compra, com ou sem vendas, sem estoque e sem pedidos pendentes (²).<br>' +
        '	(²) produtos do mix de vendas sem abastecimento => ponto de atenção para compras.<br>' +
        '■ AZUL  = Inativos, com ou sem vendas, com estoque (³).<br>' +
        '	(³) produtos para liquidação.<br>' +
        ' 											<br>' +
        'Recomendações de Uso<br>' +
        '1. Aprofunde sua investigação, utilizando a estrutura mercadológica no filtro do painel.<br>' +
        '2. Ordene as listagens – crescente ou decrescente – conforme o foco de sua análise.<br>' +
        '3. Identifique nas listagens:<br>' +
        ' ● Produtos com pedido pendentes: ação de acompanhamento e/ou substituição de fornecedor.<br>' +
        '	● Produtos sem fornecedor associado: atualize o cadastro do produto no ERP.<br>' +
        '	● Produtos para excluir(¹) do mix - Inativar no seu ERP (bloqueio de entrada).<br>' +
        '<br>' +
        '' +
        ' - Se for similar e não tiver % definido:<br>' +
        'Se um dos produtos que compõe o similar tiver estoque esta certo e não aparece<br>' +
        'Na parte de compra eles contam como UM<br>' +
        ' - Se for similar e tiver % definido:<br>' +
        'Os produtos que compõe o similar computam individualmente tanto para compra como para sem' +
        ' estoque e sem pedido.',
      virtualstockalert:
        'De acordo com o critério selecionado no quadro FILTRO:<br>' +
        ' – quanto mais específicos forem os critérios, mais eficientes serão as informações –<br>' +
        ' 											<br>' +
        'Neste gráfico, você visualiza a proporção de seu mix sem vendas em intervalos de tempo, sendo:<br>' +
        '■ D7	(de 1 a 7 dias) <br>' +
        '■ D30	(de 8 a 30 dias)<br>' +
        '■ D60	(de 31 a 60 dias)<br>' +
        '■ D90:	(de 61 a 90 dias)<br>' +
        '■ D+: 	(mais de 90 dias)<br>' +
        ' 											<br>' +
        '(X) COM ESTOQUE:<br>' +
        'Nesta seleção, você visualiza possíveis Estoques Virtuais ou falhas no abastecimento de gôndola<br>' +
        ' 											<br>' +
        '(X) TUDO: <br>' +
        'Nesta seleção, você visualiza produtos potenciais para liquidação e/ou bloqueio de entrada (Inativar)<br>' +
        ' 											<br>' +
        'Utilize um duplo clique sobre a faixa de informação que quiser detalhes. Ou no símbolo “panquecas' +
        ' empilhadas”, ao lado do “I” de informações, você obterá a lista completa de todas as classes.<br>' +
        ' 											<br>' +
        'Recomendações de Uso<br>' +
        'Quanto maior for o valor “dias sem vendas”, maiores as probabilidades de estoque virtual. Portanto,' +
        ' priorize suas análises pelos intervalos “d+”, “d90”, “d60” e regressivamente (*).<br>' +
        '(*) quando a estrutura mercadológica sofre alta deteriorização, inverta a investigação para intervalos' +
        ' de tempo crescente – exemplo: “d30”, “d60”, “d90” e sucessivamente.<br>' +
        ' 											<br>' +
        'A contagem de dias sem vendas está associada à passagem do código de produto no PDV, portanto,' +
        ' considere informações na coluna “Últ.Entrega”.<br>',
      ruptures:
        'De acordo com o critério selecionado no quadro FILTRO:<br>' +
        ' – quanto mais específicos forem os critérios, mais eficientes serão as informações –<br>' +
        ' 											<br>' +
        'Ao centro do gráfico, você visualiza o índice de rupturas de faturamento não realizado no dia anterior' +
        ' (estoque zero) para produtos que o Kikker tem predição de vendas. Com o ‘prompt’ (indicador do mouse)' +
        ' sobre o índice de ruptura, você visualiza o faturamento realizado, o faturamento não realizado e a' +
        ' margem não realizada – também referentes ao dia anterior.<br>' +
        ' 											<br>' +
        'Utilize um duplo clique sobre a faixa de informação que quiser detalhes. Ou no símbolo “panquecas' +
        ' empilhadas”, ao lado do “I” de informações, você obterá a lista completa de todas as classes.<br>' +
        ' 											<br>' +
        'Recomendações de Uso<br>' +
        '1. Investigar estoques excedentes em outras lojas, decidir sobre transferências e acionar a gestão' +
        ' de compras para uma solução definitiva.<br>' +
        '2. Estabelecer metas para este índice conforme a curva de classificação ABCDE, estrutura mercadológica' +
        ' e disponibilidade financeira para o giro de estoque.<br>' +
        ' 											<br>' +
        'Outro benefício nestas listagens refere-se às negociações futuras com os fornecedores em atraso de entrega,' +
        ' considerando as rupturas de produtos com pedidos pendentes. A lista indicará faturamento' +
        ' e margem não realizados.<br>' +
        ' 											<br>' +
        'Identifique os produtos derivados/ filhos/ compostos/ ‘de receita’ e direcione aos respectivos' +
        ' encarregados de setor para as devidas correções cadastrais a fim de tais produtos não' +
        ' interferirem nas medições de rupturas.<br>',
      virtualAlert:
        'Com a projeção de demanda o Kikker entende o comportamento de venda de cada produto e caso' +
        ' a venda saia do comportamento mesmo com estoque suficiente apontamos a provável ruptura,' +
        ' provavelmente causada por estoque virtual ou desabastecimento de gondolas. Sendo necessário' +
        '  ação do operacional e comercial a fim de ajuste de estoque e abastecimento.',
      negative:
        'De acordo com o critério selecionado no quadro FILTRO:<br>' +
        ' – quanto mais específicos forem os critérios, mais eficientes serão as informações –<br>' +
        ' 											<br>' +
        'Neste gráfico, você visualiza as vendas ‘pendentes’, ou seja, saídas de estoque zerado.<br>' +
        ' 											<br>' +
        'É necessário realizar, sistematicamente, o comparativo do estoque físico com o estoque gerado' +
        ' pelo sistema de gestão e também com o estoque fiscal. O objetivo é identificar erros de cadastro,' +
        ' entradas de notas fiscais, emissões de notas fiscais e outras inconsistências que ocasionam' +
        ' divergências entre os estoques, para que sejam realizados os ajustes necessários.<br>' +
        ' 											<br>' +
        'Utilize um duplo clique sobre a faixa de informação que quiser detalhes. Ou no símbolo “panquecas' +
        ' empilhadas”, ao lado do “I” de informações, você obterá a lista completa de todas as classes.<br>' +
        ' 											<br>' +
        'Atenção para as possíveis causas<br>' +
        ' 											<br>' +
        'É comum haver códigos de entrada que não têm saída – a saída dá-se por outro código, o que' +
        ' provoca inchaço de estoque e pode configurar omissão de saída (já que não existe estoque físico' +
        ' compatível). Da mesma forma, o item que sai do estoque nunca entrou, caracteriza omissão de entrada' +
        ' (estoque negativo).<br>' +
        ' 											<br>' +
        'Outra situação comum é quando a empresa compra o mesmo produto de fornecedores diferentes. Por' +
        ' exemplo, o Fornecedor A vende caixa com 24 unidades; o Fornecedor B vende caixa com 12 unidades;' +
        ' já o Fornecedor C vende unidades.<br>',

      last30:
        'De acordo com o critério selecionado no quadro FILTRO:<br>' +
        ' – quanto mais específicos forem os critérios, mais eficientes serão as informações –<br>' +
        ' 											<br>' +
        'Neste gráfico, você visualiza os excedentes de estoque em relação às predições de vendas, conforme' +
        ' período de tempo em vendas futuras que você selecionar.<br>' +
        ' 											<br>' +
        'As projeções disponíveis para as vendas futuras são de:<br>' +
        '■ 15 dias<br>' +
        '■ 30 dias<br>' +
        '■ 45 dias<br>' +
        '■ 60 dias<br>' +
        '■ 90 dias<br>' +
        ' 											<br>' +
        'Utilize um duplo clique sobre a faixa de informação que quiser detalhes. Ou no símbolo “panquecas' +
        ' empilhadas”, ao lado do “I” de informações, você obterá a lista completa de todas as classes.<br>' +
        ' 											<br>' +
        'Recomendação de Uso<br>' +
        ' 											<br>' +
        'Identifique os itens que o volume (valor/quantidade) em estoque dão oportunidade para uma ação de' +
        ' liquidação. O objetivo é gerar liquidez financeira para o abastecimento adequado e redução contínua' +
        ' de rupturas de estoque de produtos que há demanda conhecida e prevista.<br>' +
        ' 											<br>' +
        'Estoque na classificação X significa que não há nem o rastro de venda destes produtos e, portanto,' +
        ' priorize esta classe em suas iniciativas de investigação e análise. É provável que  grande parte' +
        ' deste excedente seja apenas virtual (ação para inventário), ou pode também tratar-se daqueles produtos' +
        ' que entram com um código e saem em outro código (relacionados às vendas pendentes). Necessário corrigir' +
        ' cadastros e ‘dar as baixas’ (saídas).<br>' +
        ' 											<br>' +
        'Este gráfico é muito útil para acompanhar a migração do recurso financeiro aplicado em estoque – das' +
        ' classes de menor giro para as classes de maior giro da curva de Paretto – à medida que houver aderência' +
        ' de seus compradores às sugestões de compras do Kikker. <br>',
      virtualStockAlert: `
        Indicador que mostra os produtos de cada loja com maior probabilidade de estarem com falta de vendas devido a estoque virtual ou falha na exposição, ou até mesmo, falha de precificação.
        <br>
        <br>
        Os grupos de  probabilidade se divide em:<br>
        -Muito Alta<br>
        -Alta<br>
        -Média<br>
        -Baixa<br>
        <br>
        A taxa em % no centro do gráfico demonstra a proporção dos ítens com maior probabilidade de perda de venda em função ao total de ítens ativos sempre com foco nos grupos Muito Alto e Alto baseado no comportamento de D-1.
        <br>
        <br>
        A informação  considera o ciclo/comportamento de venda de cada produto. quanto maior a probabilidade, mais provável do produto loja estar com algum problema e deverá ser atuado diariamente para colocar o produto disponível em gôndola.
      `,
      rupturesalert:
        'De acordo com o critério selecionado no quadro FILTRO:<br>' +
        ' – quanto mais específicos forem os critérios, mais eficientes serão as informações –<br>' +
        ' 											<br>' +
        'Neste gráfico, você visualiza os produtos que, pelas projeções de vendas, terão seus estoques zerados,' +
        ' em dias, nas seguintes faixas de tempo:<br>' +
        '■ D7	(de 1 a 7 dias) <br>' +
        '■ D15	(de 8 a 15 dias)<br>' +
        '■ D30	(de 16 a 30 dias)<br>' +
        ' 											<br>' +
        'Utilize um duplo clique sobre a faixa de informação que quiser detalhes.<br>' +
        ' 											<br>' +
        'Recomendação de Uso<br>' +
        ' 											<br>' +
        'Primeiramente, investigue estoques excedentes em outras lojas – à medida da aderência às sugestões de' +
        ' compras do Kikker, eliminará excessos de estoque para transferência entre lojas.<br>' +
        ' 											<br>' +
        'Responsável de compras deve assumir ação de abastecimento. Utilize a ordenação – crescente ou decrescentes' +
        ' – por pedidos pendentes e por classe – para identificar possíveis antecipações de pedidos de compras.<br>' +
        ' 											<br>' +
        'Atenção: garanta as configurações de pedidos no Kikker atualizadas:<br>' +
        ' 											<br>' +
        'Nos parâmetros de sugestão de pedidos de compras, o gestor pode definir se o estoque atual deve ser ou' +
        ' não considerado para o alerta de rupturas.<br>' +
        ' 											<br>' +
        'Outro parâmetro, definido por departamento/setor/categoria/sub-categoria, determina se o estoque atual' +
        ' deve ou não ser considerado para as coberturas nas predições de vendas – como exemplo, sugerimos não' +
        ' considerar estoque atual para departamentos/setores de alto grau de perda e/ou deterioração. <br>',
      abcshare:
        'De acordo com o critério selecionado no quadro FILTRO, pode-se analisar qualquer nível da estrutura' +
        ' mercadológica.<br>' +
        '                        <br>' +
        'Neste gráfico, você visualiza:<br>' +
        '■ 10% dos produtos com maior faturamento<br>' +
        '■ 20% dos produtos com maior faturmento<br>' +
        '■ 50% dos produtos com maior faturamento<br>' +
        '■ 80% dos produtos com maior faturamento<br>' +
        '                        <br>' +
        '(X) VENDAS:<br>' +
        'Nesta seleção, você visualiza a participação dos produtos em relação ao faturamento – ou seja, quanto' +
        ' do seu mix teve giro nos últimos 30 (trinta) dias média móvel.<br>' +
        'Ao posicionar o mouse sobre o ponto da curva reflete o valor faturado até a data D-1 sendo na média' +
        ' móvel 30 dias.<br>' +
        ' 											<br>' +
        '(X) ESTOQUE:<br>' +
        'Nesta seleção, você visualiza a participação dos produtos em relação ao estoque em valor para os' +
        ' últimos 30 (trinta) dias média móvel.<br>' +
        'Ao posicionar o mouse sobre o ponto da curva reflete o valor do estoque até a data D-1 sendo na' +
        ' média móvel 30 dias.<br>' +
        ' 											<br>' +
        'Recomendação de Uso<br>' +
        ' 											<br>' +
        'Utilize um duplo clique sobre o ponto de informação (interseção das faixas no eixo X com a linha' +
        ' do gráfico) para detalhes.<br>' +
        ' 											<br>' +
        'O que é média móvel 30 dias.<br>' +
        'É o valor somado dos ultimos 30 dias divido por 30, que traz como resultado a média aritmetica' +
        ' dos ultimos 30 dias.<br>' +
        'No dia seguinte, o Kikker exclui o valor do dia mais antigo e acrescenta mais um dia novo, de' +
        ' forma que sempre teremos a média aritmetica dos ultimos 30 dias.<br>' +
        'O valor da média móvel 30 dias comparado com o <b>faturamento ou estoque</b> do mês passado podemos' +
        ' entender se estamos em um viez crescente ou decrescente em relação ao ultimo mês.<br>' +
        ' 											<br>' +
        'Outra análise importante que podemos fazer neste gráfico é o potencial que está sendo explorado' +
        ' do mix selecionado no filtro.<br>' +
        'Quanto mais abrangente a curva, iniciando do ponto mais baixo a esquerda do gráfico e chegando' +
        ' o mais alto possível chegando ao extremo superior direito do gráfico temos o mix sendo bem' +
        ' dimencionado.<br>' +
        'Quando o gráfico apresenta "cotovelos" na curva, isso indica que o mix está ruim devendo ser revisado.<br>',

      classshares:
        'De acordo com o critério selecionado no quadro FILTRO:<br>' +
        ' – quanto mais específicos forem os critérios, mais eficientes serão as informações –<br>' +
        ' 											<br>' +
        'Neste gráfico, você visualiza os produtos por classe que, no dia de ontem, estavam com:<br>' +
        '■ Curva ABC;<br>' +
        '■ Margem negativa;<br>' +
        '■ Produtos vendidos;<br>' +
        '■ Produtos em rupturas;<br>' +
        '■ Variações de custo de mercadoria;<br>' +
        '■ Variações de preços de vendas.<br>' +
        ' 											<br>' +
        'Utilize um duplo clique sobre a faixa de informação que quiser detalhes.<br>' +
        ' 											<br>' +
        'Recomendação de Uso<br>' +
        ' 											<br>' +
        'Investigue erro de negociação em compras nas variações de custo e erro de precificação nas' +
        ' variações de venda. <br>' +
        ' 											<br>' +
        'Atenção para a lista de produtos com margem negativa (bonificações negociadas).<br>',
      network_performance:
        'De acordo com o critério selecionado no quadro FILTRO, a curva exibe dados em qualquer nivel' +
        ' da estrutura mercadológica.<br>' +
        ' 											<br>' +
        'Neste gráfico, você visualiza a curva, por semana ou por mês, as apurações de:<br>' +
        '● Vendas<br>' +
        'O valor exibido é o valor faturado acumulado no período em análise, mensal ou semanal.<br>' +
        ' 											<br>' +
        '● Rupturas<br>' +
        'O valor exibido é referente a média simples apurada no período em análise, mensal ou semanal.<br>' +
        ' 											<br>' +
        '● Quebras<br>' +
        'O valor exibido é o total de quebras conhecidas e desconhecidas apurada no período em análise,' +
        ' mensal ou semanal.<br>' +
        'Importante: o valor exibido no Kikker é o valor lançado pelo cliente não tendo nenhuma tratativa' +
        ' de dados nesta informação.<br>' +
        ' 											<br>' +
        '● Estoque<br>' +
        'Se analise mensal, o valor exibido é a média do estoque no mês em analise, somatória do mês em' +
        ' análise dividido por 30.<br>' +
        'Se analise semanal, o valor exibido é a média do estoque na semana em análise, somatória da semana' +
        ' em análise dividido por 7.<br>' +
        ' 											<br>' +
        '● Taxa de rupturas<br>' +
        'O valor exibido é a ruptura apurada no período dividida pelo faturamento do período, ou seja, qual' +
        ' o percentual de ruptura apurado no período.<br>' +
        ' 											<br>' +
        '● Giro de estoque.<br>' +
        'O valor exibido é a quantidade de vezes que o estoque gira em relação ao faturamento do período' +
        ' analisado, ex.: Faturamento de 100 com estoque médio de 50, isso equivale a 2. Giro de estoque' +
        ' igual a duas vezes.<br>' +
        ' 											<br>' +
        'Posicione o <b>prompt</b> do mouse sobre a linha nas referências do eixo X para detalhes (legenda).<br>',
      curve_abc:
        'Demonstra a venda do dia anterior distribuída pela classificação ABC, onde cada curva representa' +
        ' sua porcentagem do faturamento dos últimos 90 dias<br>' +
        '● Curva A = 50%<br>' +
        '● Curva B = 20%<br>' +
        '● Curva C = 15%<br>' +
        '● Curva D = 10%<br>' +
        '● Curva E = 5%<br>',
      top_sales: 'São os 20% dos SKU’s mais vendidos do mix de produtos. Referente à média dos últimos 90 dias.',
      cost_var:
        'Demonstra os itens que tiveram a última compra com variação de 8% para baixo ou para cima em' +
        ' relação a última compra, objetivo é evidenciar variação brusca na compra.',
      sale_var:
        'Demonstra os itens que tiveram na última venda variação de 8% para baixo ou para cima em relação' +
        ' a última venda, objetivo é encontrar erros de precificação.',
      top_ruptures: 'São 20% dos SKU’s que rompem frequentemente. Referente à média dos últimos 90 dias.',
      top_ruptures_in_value: 'Os 20% dos SKU’s que mais tiveram perda por ruptura. Referente aos últimos 30 dias.',
      top_ruptures_recidivism:
        'Os 20% dos SKU’s que estiveram em ruptura por mais dias. Referente aos últimos 30 dias.',
      top_ruptures_lost_products:
        'São 20% dos SKU’s que mais rompem frequentemente em quantidade. Referente aos últimos 30 dias.',
      top_breaks_in_value: 'Os 20% dos SKU’s que mais tiveram perda por quebra. Referente aos últimos 30 dias.',
      top_breaks_recidivism: 'Os 20% dos SKU’s que estiveram em quebra por mais dias. Referente aos últimos 30 dias.',
      top_breaks_lost_products:
        'São 20% dos SKU’s que mais rompem frequentemente em quantidade. Referente aos últimos 30 dias.',
      ruptureslogistics:
        'Mostra os produtos que tiveram rupturas nas lojas, mas estão disponíveis no Centro de Distribuição',
    },
    monitoring: {
      title:
        'Na opção Monitoramento, temos a possibilidade de salvar até 7 modelos de filtro, por usuário, ' +
        'para caso tenha algum tipo de análise padrão, facilitar o processo deixando todos os tipos de filtros ' +
        'utilizados salvos em um modelo. Para gravar os modelos, selecione os filtros desejados ' +
        'Após a seleção, clicar no símbolo de DISQUETE em um dos padrões abaixo. Pronto seu modelo de ' +
        'filtros estará salvo e poderá ser utilizado sempre que desejar. Para carregar os filtros salvos em um ' +
        'monitoramento, basta clicar em cima do número do padrão desejado. Caso queira apagar algum ' +
        'monitoramento salvo. Basta clicar no ícone da LIXEIRA',
    },
    cockpit_selectors: {
      business_group: 'Grupo Econômico - necessário o preenchimento',
      regional: 'Divisão dentro do grupo econômico - necessário o preenchimento',
      store_cluster: 'Agrupamento de lojas',
      supplier: 'Código ou Nome do fornecedor (deixe em branco para todos)',
      store: 'Código ou Nome da unidade (deixe em branco para todas)',
      market_structure:
        'Departamento > Setor > Categoria > Subcategoria (em branco para todos níveis) - clique' +
        ' sobre a seleção para expandir os níveis -',
      product_families: 'Família do Produto (em branco para todos níveis) -',
      product_supply_types:
        'Recebimento da mercadoria pelo fornecedor ou pelo centro de distribuição (em branco' + ' para ambos)',
      product_class:
        'Classe da curva ABCDE - Paretto (em branco para todas as classes) – X refere-se a produtos' +
        ' sem vendas há mais de 90 (dias) ou produtos novos em lançamento (novos)',
      product: 'Código interno ou Descrição de um ítem (SKU-StoreKeepingUnit) - em branco para todos os produtos -',
      erp_buyer: 'Código ou Nome do Comprador (deixe em branco para todos)',
      launch:
        'SÓ LANÇAMENTOS: são os produtos cadastrados nos últimos 90 (noventa) dias corridos. SEM LANÇAMENTOS:' +
        ' são somente os produtos cadastrados há mais de 90 (noventa) dias corridos. Em branco para TODOS PRODUTOS.' +
        ' - obs. data de cadastro informada no quadro DETALHES > PRODUTO > ENTROU EM LINHA -',
      promotion:
        'SÓ PROMOÇÃO: Apenas os produtos em promoção. SEM PROMOÇÃO: Produtos que não estão em promoção.' +
        ' TODOS OS PRODUTOS: Todos os produtos em promoção ou não.',
    },
    buyers_view: {
      performance: 'Preencher',
      participation: 'Preencher',
      mix: 'Preencher',
      ruptures: 'Preencher',
      quality_excess: 'Preencher',
      ruptures_alert: 'Preencher',
    },
    sku_detail: {
      product_rounding:
        'Alterar dados para todos, na parte superior, permite realizar uma única alteração que' +
        ' se aplica a todas as lojas ou os dados podem ser alterados loja a loja, bastando ir na linha da loja. <br/>' +
        'Tipos de Arredondamento <br>' +
        '• Unidade de compra → Arredondado o produto para unidade de compra. É necessário definir o Percentual' +
        ' de arredondamento no campo, “Arredondamento da Unid. de Compra”. Ao digitar, por exemplo 30%, o valor' +
        ' do pedido calculado, da parte não inteira, que for maior que 0,3 será arredondando para cima, senão' +
        ' para baixo. Exemplo pedido em unidade de compras foi de 10,2, será arredondado para 10. Pedido foi de' +
        ' 10,3, será arredondado para 11. <br>' +
        '• Palete → Arredonda a compra para paletes. É necessário definir o “Arredondamento da Unid. deCompra”,' +
        ' pois inicialmente haverá ajuste em unidade de compras. E também se deve definir“Arredondamento da unidade' +
        ' de pedido” que fará o arredondamento do palete. Exemplo: <br>' +
        '◦ Arredondamento da Unid. de Compra: 30% <br>' +
        '◦ Arredondamento da unidade de pedido: 25% <br>' +
        '◦ Capacidade do Palete: 100 caixas <br>' +
        '◦ Pedido foi de 24,3 caixas, será arredondado para 25 caixas. Como 25 caixas atendem 25% do palete teremos' +
        ' um pedido de 100 caixas, ou seja, um palete <br>' +
        '• Camada → Arredonda a compra para camadas do paletes. É necessário definir o“Arredondamento da Unid. de' +
        ' Compra”, pois inicialmente haverá ajuste em unidade de compras. <br>' +
        'E também se deve definir “Arredondamento da unidade de pedido” que fará o arredondamento dacamada do' +
        ' palete. Exemplo: <br>' +
        '◦ Arredondamento da Unid. de Compra: 30% <br>' +
        '◦ Arredondamento da unidade de pedido: 30% <br>' +
        '◦ Capacidade da camada: 10 caixas <br>' +
        '• Pedido foi de 24,3 caixas, será arredondado para 25 caixas. Como 25 caixas atendem 2,5 camadas teremos' +
        ' um pedido de 30 caixas, ou seja, 3 camadas <br>' +
        '• Fator de multiplicação unidade de compra → Realiza o pedido em múltiplos de unidade decompra. <br>' +
        '◦ Arredondamento da Unid. de Compra: 30% <br>' +
        '◦ Arredondamento da unidade de pedido: 30% <br>' +
        '◦ Fator de multiplicação de unidade de compra: 20 <br>' +
        '◦ Pedido em unidade de compra 25,2, será arredondado para 25 caixas. 5 caixas são 25% do fator de' +
        ' multiplicação (5/20=0,25) e o Arredondamento da unidade de pedido é de 30%, opedido será de 20' +
        ' unidades de compra. <br>' +
        '• Fator de multiplicação unidade de venda → Realiza o pedido em múltiplos de unidade de venda. <br>' +
        '◦ Arredondamento da Unid. de Compra: 30% <br>' +
        '◦ Arredondamento da unidade de pedido: 30% <br>' +
        '◦ Fator de multiplicação de unidade de venda: 20 <br>' +
        '◦ Unidade de vendas por unidade de compra: 10 unidades <br>' +
        '◦ Pedido em unidade de compra 25,2, será arredondado para 25 caixas, ou seja, 250 unidades devenda.' +
        ' 250/20=12,5 o Arredondamento da unidade de pedido é de 30%, será arredondado para13 fatores de' +
        ' multiplicação, ou seja, o pedido será de 260 unidades de venda ou 26 unidadesde compra. <br>',
    },
  },
};
