const requests = {};
// eslint-disable-next-line no-global-assign
const $ = (jQuery = require('jquery'));

module.exports = function (opts) {
  // document.body.style.cursor = 'progress';
  const lastRequest = requests[opts.url];

  if (lastRequest && opts.url.indexOf('/api/') > -1) return;
  if (['POST', 'PUT', 'DELETE'].includes((opts.type || opts.method || '').toUpperCase())) {
    let data = opts.data;

    if (data instanceof FormData) {
      opts.contentType = false;
      opts.processData = false;
      opts.enctype = 'multipart/form-data';
    }

    let contentType = opts.contentType;

    if (contentType === '' || contentType === null || contentType === undefined) contentType = 'application/json';
    const defaultHeaders = { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') };

    if (contentType) {
      defaultHeaders['Content-Type'] = contentType;
      if (data instanceof Object) data = JSON.stringify(data);
    }

    opts = Object.assign(opts, {
      data,
      headers: Object.assign(defaultHeaders, opts.headers),
    });
  }

  const oldComplete = opts.complete;
  const oldFailure = opts.error;
  opts = Object.assign(opts, {
    complete: function (data) {
      delete requests[opts.url];
      // if(!Object.keys(requests).length) document.body.style.cursor = 'default';

      if (typeof oldComplete === 'function') oldComplete(data);
    },
    error: function (error) {
      delete requests[opts.url];
      // if(!Object.keys(requests).length) document.body.style.cursor = 'default';

      if (typeof oldFailure === 'function') oldFailure(error);
    },
  });
  const request = $.ajax(opts);
  requests[opts.url] = request;
  return request;
};
